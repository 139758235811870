import { render, staticRenderFns } from "./LiveFeed.vue?vue&type=template&id=5f615150&scoped=true"
import script from "./LiveFeed.vue?vue&type=script&lang=ts"
export * from "./LiveFeed.vue?vue&type=script&lang=ts"
import style0 from "./LiveFeed.vue?vue&type=style&index=0&id=5f615150&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f615150",
  null
  
)

export default component.exports